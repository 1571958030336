import { Link } from '@reach/router';
import { Menu } from 'antd';
import * as _ from 'lodash';
import React, { useState } from 'react';
import Header from '../components/Header/Header';
import SEO from '../components/seo';
import '../static/landing.css';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

const { SubMenu } = Menu;

const StaticList = ({pageContext}) => {
  const { posts, categories, special, category } = pageContext;
  const jerarquizedCategories = _.groupBy(categories, 'parent');
  const [mainCategories, setMainCategories] = useState(jerarquizedCategories[0]);
  //console.log(pageContext);

  let breadcrumbArray = [{url: "/", text: "Home"}];

  if(category){
    breadcrumbArray.push({url: "/posts/", text: `${category.name}`});
  }else{
    breadcrumbArray.push({url: "/posts/", text: "Posts"});
  }
 
  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <SEO
        title="Post list"
        description="Post list"
        url={{
          es: '', en: '', de: '', fr: '',
        }}
        lang="es"
      />
      <Header scrollclass="HeaderContainerBoatDetailScrollMobile" page="boatDetail" special={special} />
      <Menu
        id="categories"
        style={{
          margin: '64px auto', // , width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
        className="MenuPost"
        mode="horizontal"
      >
        {
          mainCategories.map((category) => (
            <SubMenu
              title={(
                <Link to={`/category/${category.slug}/`}>{category.name}</Link>
              )}
            >
              {
              (jerarquizedCategories[category.id])
                ? (
                  <Menu.ItemGroup>
                    {
                    jerarquizedCategories[category.id].map((subCategory) => (
                      <Menu.Item key={`/category/${subCategory.slug}`}>
                        <Link to={`/category/${subCategory.slug}/`}>{subCategory.name}</Link>
                      </Menu.Item>
                    ))
                  }
                  </Menu.ItemGroup>
                )
                : ''
              }
            </SubMenu>
          ))
        }
      </Menu>
      <div style={{
        width: '800px',
        maxWidth: '90%',
        margin: '80px auto',
        backgroundColor: '#fff',
        padding: '16px',
      }}
      >
        <Breadcrumb breadcrumb={breadcrumbArray}></Breadcrumb>
        <h1 style={{
          width: '100%',
          fontFamily: 'var(--main-font)',
          color: 'var(--main-color)',
          marginBottom: '10px',
          maxWidth: '1440px',
          textAlign: 'left',
        }}
        >
Posts

        </h1>
        <div style={{ width: '100%', height: '1px', backgroundColor: '#ccc' }} />
        {posts.map((post) => (<div style={{ marginTop: '16px', marginBottom: '16px' }}><Link to={`/es/${post.slug}/`}>{post.title.rendered.replace('⛵', '').trim()}</Link></div>))}
      </div>
    </div>
  );
};

export default StaticList;
